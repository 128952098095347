var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer-wrapper mobile-only"},[_c('div',{attrs:{"id":"top-nav"}},[(_vm.tripID)?_c('div',{on:{"click":function($event){return _vm.back()}}},[_c('img',{staticClass:"back-icon",attrs:{"src":require("@/assets/back-icon.png"),"alt":"back"}})]):_c('router-link',{attrs:{"to":{ name: 'Trip', params: { trip_id: this.tripID }}}},[_c('img',{staticClass:"back-icon",attrs:{"src":require("@/assets/back-icon.png"),"alt":"back"}})])],1),_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"heading4 main-heading"},[_vm._v("Review Your Booking & Accept Terms and Conditions")]),_c('Loader',{attrs:{"loading":_vm.trip.loading,"error":_vm.trip.error}}),(!_vm.trip.loading && !_vm.trip.error)?_c('div',_vm._l((_vm.tripItems),function(item,index){return _c('span',{key:index},[_c('TripItem',{staticClass:"item",attrs:{"id":item.id,"tripID":_vm.trip.tripID,"type":item.type,"name":item.name,"price":item.price,"date":item.date,"travellers":item.travellers,"bookingStatus":item.bookingStatus,"alert":item.alerts.all.length > 0 ? item.alerts.all[0] : false,"data":item.data,"showContinueButton":false,"loadOpen":"true"}})],1)}),0):_vm._e()],1),(!_vm.trip.loading && !_vm.trip.error)?_c('div',[_c('TripItemsSummary',{attrs:{"tripID":_vm.trip.tripID,"groupedTripItems":{
				'Bookable': {
					items: this.tripItems,
					totalPrice: this.totalPrice,
					heading: 'Booking Summary'
				}
			}}}),_c('div',{staticClass:"terms inner-wrapper"},[_c('div',{staticClass:"checkbox",on:{"click":_vm.toggleCheckbox}},[(!_vm.agreedToTerms)?_c('div',{staticClass:"unchecked"}):_vm._e(),(_vm.agreedToTerms)?_c('div',{staticClass:"checked"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"30","height":"30","viewBox":"0 0 50 50"}},[_c('polyline',{attrs:{"points":"10,25 20,35 40,15","stroke":"#808080","stroke-width":"4","fill":"none"}})])]):_vm._e()]),_c('label',{attrs:{"for":"terms"},on:{"click":_vm.toggleCheckbox}},[_vm._v(" I have read and accept the "),_c('router-link',{attrs:{"to":{ name: 'Terms and Conditions', query: { redirect: this.$route.path } }}},[_vm._v("Terms and Conditions")]),_vm._v(", the "),_c('router-link',{attrs:{"to":{ name: 'Licences', query: { redirect: this.$route.path } }}},[_vm._v("Licences")]),_vm._v(" and "),_c('router-link',{attrs:{"to":{ name: 'Privacy Policy', query: { redirect: this.$route.path } }}},[_vm._v("Privacy Policy")]),_vm._v(" pages. ")],1),(_vm.agreedToTermsError && !_vm.agreedToTerms)?_c('p',{staticClass:"error"},[_vm._v("You must agree to the terms and conditions.")]):_vm._e(),_vm._m(0)]),_c('button',{staticClass:"button",class:{ disabled: !_vm.agreedToTerms || 1 },attrs:{"disabled":!_vm.agreedToTerms || 1},on:{"click":function($event){return _vm.goToPayment()}}},[_vm._v("Confirm & Pay")])],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"error",staticStyle:{"margin-top":"30px"}},[_vm._v("Sorry! The app is in demonstration mode and we are not currently accepting any bookings. "),_c('br'),_vm._v("Contact "),_c('a',{attrs:{"href":"mailto:contact@purpose.travel"}},[_vm._v("contact@purpose.travel")]),_vm._v(" to enquire.")])
}]

export { render, staticRenderFns }